.cardleft {
  display: inline-block;
  align-items: center;
  text-align: center;
  width: 200px;
  height: 400px;
  align-self: center;
  justify-self: center;
}
.addbg {
  background-image: url("./../../assets/img/bubble.png");
}
.coledit {
  display: flex;
  flex-direction: column;
  padding: 0%;
  margin: 3px;
}
.aligner {
  justify-content: center;
  align-items: center;
  align-self: center;
  justify-self: center;
}
