.hero {
  background-color: rgb(31, 28, 28);
}
.heading {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 40px;
  font-weight: bolder;
}
/* .rightshape {
  background-color: rgb(5, 5, 88);
  width: 100hl;
  height: 100vh;
  margin-top: -65px;
  border-radius: 0px 0px 0px 200px;
} */
