.thebg {
  background-image: url("./../../assets/img/brand/fav.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  padding-top: 50px;
  padding-bottom: 50px;
  font-weight: 600;
}

.bgcolor {
  background-color: rgb(45, 54, 63);
}
